<template>
  <div style="position:relative;height:100%">
    <!-- <surround-box :width="400" title="设备设施统计" contBackground="#0f3266">
      <div class="content-box">
        <div class="popbox sb">
          <span>{{ statResult.farmCount }}</span>
          <span>集散点(个)</span>
        </div> -->
    <!-- <div class="popbox yzgm">
          <span>{{ statResult.deviceCount+120 }}</span>
          <span>设备(台)</span>
        </div>
        <div class="popbox ss">
          <span>{{ statResult.facilityCount+200 }}</span>
          <span>设施(台)</span>
        </div> -->
    <!-- <div class="popbox jcd">
          <span>{{ statResult.authCount + 20 }}</span>
          <span>监测点(个)</span>
        </div> -->
    <!-- <div class="popbox car">
          <span>{{statResult.carCount+50}}</span>
          <span>转运车(辆)</span>
        </div> -->
    <!-- </div> -->
    <!-- <div class="statistics">
      <div class="statistics-content">
        <div class="conts-item">
          <div class="item-box">
            <p class="num">{{ statResult.farmCount }}</p>
            <p>集散点(个)</p>
          </div>
          <div class="item-box">
            <p class="num">{{ statResult.deviceCount }}</p>
            <p>设备(台)</p>
          </div>
          <div class="item-box">
            <p class="num">{{ statResult.facilityCount }}</p>
            <p>设施(台)</p>
          </div>
          <div class="item-box" v-for="(item, index) in statics2" :key="index">
            <p class="num">{{ item.content }}</p>
            <p>{{ item.name + "(" + item.unit + ")" }}</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="chart" ref="deviceChart"></div> -->
    <!-- </surround-box> -->
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">产能分析</div>
      </div>
    </div>

    <surround-box
      :width="400"
      title="利用产能统计"
      style="position:absolute;left:0;bottom:32px;"
      contBackground="#0f3266"
    >
      <div class="cont-box">
        <progress-ball
          :percent="percent"
          :width="150"
          :height="150"
          isDrawText
        ></progress-ball>
        <div>
          <p class="percent">
            {{
              statics2.length > 0
                ? statics2.find((item) => item.name.indexOf("利用产能") > -1)
                    .content + "%"
                : ""
            }}
          </p>
          <p>累计利用产能</p>
        </div>
      </div>
    </surround-box>
    <!-- <surround-box :width="400" title="信息数据统计">
      <div class="chart" ref="infoChart"></div>
    </surround-box> -->
  </div>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";
import progressBall from "@/components/progressBall";
export default {
  components: {
    surroundBox,
    progressBall,
  },
  name: "",
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      statResult: {},
      statics2: [],

      ctx: null,
      percent: 0,
    };
  },
  watch: {
    currentFarm() {
      this.getStatResult();
      this.getResource2();
    },
  },
  methods: {
    getStatResult() {
      this.$post(this.$api.STATICTICS_MAP.USEING, {
        siteType: "3",
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.statResult = res;
        // this.initDeviceChart();
      });
    },
    //统计数据
    getResource2() {
      this.$post(this.$api.STATICS_MANAGE.GET4, {
        pid: 1,
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.statics2 = res;
        this.percent = res.find(
          (item) => item.name.indexOf("利用产能") > -1
        ).content;
        this.initInfoChart();
      });
    },
    // initDeviceChart() {
    //   let chart = echarts.init(this.$refs.deviceChart);
    //   let option = {
    //     grid: {
    //       top: 20,
    //       left: "10%",
    //       bottom: 40,
    //       right: 20,
    //     },
    //     xAxis: {
    //       data: ["集散点(个)", "设备(台)", "设施(台)"],
    //       axisLine: {
    //         show: false,
    //         lineStyle: {
    //           color: "#fff",
    //         },
    //       },
    //       axisTick: {
    //         show: false,
    //       },
    //     },
    //     yAxis: {
    //       show: false,
    //     },
    //     series: {
    //       type: "bar",
    //       data: [
    //         this.statResult.farmCount,
    //         this.statResult.deviceCount,
    //         this.statResult.facilityCount,
    //       ],
    //       barMaxWidth: 30,
    //       label: {
    //         show: true,
    //         position: "top",
    //         color: "#fff",
    //       },
    //       itemStyle: {
    //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //           {
    //             offset: 0,
    //             color: "#947DFF",
    //           },
    //           {
    //             offset: 1,
    //             color: "#23A6F2",
    //           },
    //         ]),
    //       },
    //     },
    //   };
    //   chart.setOption(option);
    // },
    initInfoChart() {
      let chart = echarts.init(this.$refs.infoChart);
      let option = {
        grid: {
          top: 20,
          left: 0,
          bottom: 40,
          right: 20,
        },
        xAxis: {
          data: this.statics2
            .filter((item) => item.name.indexOf("利用产能") == -1)
            .map((el) => el.name),
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: false,
        },
        series: {
          type: "bar",
          data: this.statics2
            .filter((item) => item.name.indexOf("利用产能") == -1)
            .map((el) => el.content),
          barMaxWidth: 30,
          label: {
            show: true,
            position: "top",
            color: "#fff",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#947DFF",
              },
              {
                offset: 1,
                color: "#23A6F2",
              },
            ]),
          },
        },
      };
      chart.setOption(option);
    },
  },
  mounted() {
    this.getStatResult();
    this.getResource2();
  },
};
</script>

<style lang="less" scoped>
@import '../common.less';

.statistics {
  width: 100%;
  height: 100%;
  padding: 16px;
  // background: rgba(23, 34, 60, 0.6);
  .statistics-content {
    width: 100%;
    height: 100%;
    // border: 1px solid #d8e0f3;
    border-radius: 8px;
    .item-box {
      width: 50%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      .num {
        font-size: 30px;
      }
    }
    .cont-item {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .conts-item {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
.chart {
  width: 100%;
  height: 200px;
}
.cont-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 270px;
  padding: 20px 0;
  > div {
    flex-shrink: 0;
    flex-grow: 0;
    text-align: center;
    margin-left: 20px;
    .percent {
      font-size: 26px;
      color: #08dddd;
    }
  }
}
.content-box {
  width: 100%;
  height: 270px;
  position: relative;
  .popbox {
    // background: radial-gradient(
    //   rgba(0, 255, 220, 0) 0%,
    //   rgba(0, 255, 220, 0.2) 70%,
    //   rgba(0, 255, 220, 0.7) 85%,
    //   rgba(0, 255, 220, 1) 100%
    // );
    background: radial-gradient(
      rgba(0, 255, 220, 0) 55%,
      rgba(0, 145, 255, 0.2) 70%,
      rgba(0, 145, 255, 0.7) 85%,
      rgba(0, 145, 255, 1) 100%
    );
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > span:first-child {
      color: #36d8ff;
      font-size: 22px;
    }
    > span:last-child {
      color: #fff;
      font-size: 12px;
    }
  }
  .sb {
    left: 40px;
    top: 26px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
  .yzgm {
    left: 194px;
    top: 40px;
    width: 120px;
    height: 120px;
    border-radius: 70px;
  }
  .ss {
    left: 118px;
    top: 145px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .jcd {
    left: 220px;
    top: 105px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
  .car {
    left: 300px;
    top: 145px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}


</style>
